<Page {onPageInit} name="recipe-collection" class="recipe-collection-page">
    <Navbar title={pageTitle} backLink="Back">
        <Subnavbar inner={false}>
            <Searchbar 
                searchContainer=".search-list" 
                searchIn=".item-title, .item-subtitle, .item-text"
                backdrop={false}
            />
        </Subnavbar>
    </Navbar>
    <List dividersIos mediaList outlineIos strongIos class="searchbar-not-found">
        <ListItem title="Nothing found" />
    </List>
    {#if collectionType == 'USER_CREATED'}
        <List dividersIos mediaList outlineIos strongIos class="search-list">
            {#if $userRecipes && $userRecipes.length > 0}
            {#each $userRecipes as recipe}
                {#key recipe.id}
                    <ListItem
                        link="#"
                        swipeout
                        title={recipe.name}
                        subtitle="By: {recipe.publisher || recipe.source || ( getUserFirstLastName($userDataStore).firstName ? getUserFirstLastName($userDataStore).firstName : "" ) + " " + ( getUserFirstLastName($userDataStore).lastName ? getUserFirstLastName($userDataStore).lastName : "" )}"
                        text={recipe.description}
                        on:click={() => handleOnClickUserRecipe(recipe, self)}
                        onSwipeoutDeleted={() => handleRemoveRecipe(recipe)}
                    >
                        <SwipeoutActions right>
                            <SwipeoutButton delete>
                            Remove
                            </SwipeoutButton>
                        </SwipeoutActions>
                        <!-- svelte-ignore a11y-missing-attribute -->
                        <div 
                            slot="media"
                            style="
                                border-radius: 8px;
                                width: 80px;
                                height: 80px;
                                background-image: url({recipe.image});
                                background-size: cover;
                                background-position: center;
                            "
                        >
                        </div>
                    </ListItem>
                {/key}
            {/each}
            {/if}
        </List>
    {:else if collectionType == 'USER_FAVOURITES'}
        {#if favouriteRecipesData && favouriteRecipesData.length > 0}
            <List dividersIos mediaList outlineIos strongIos class="search-list">
                {#each favouriteRecipesData as recipe}
                    {#key recipe.id}
                        <ListItem
                            link="#"
                            title={recipe.name || recipe.label}
                            subtitle="By: {recipe.publisher || recipe.source || ( getUserFirstLastName($userDataStore).firstName ? getUserFirstLastName($userDataStore).firstName : "" ) + " " + ( getUserFirstLastName($userDataStore).lastName ? getUserFirstLastName($userDataStore).lastName : "" )}"
                            text={recipe.description || null}
                            on:click={() => handleOnClickRecipe(recipe, self, false)}
                        >
                            <!-- svelte-ignore a11y-missing-attribute -->
                            <img
                                slot="media"
                                style="border-radius: 8px"
                                src={recipe.image || recipe.backgroundImage}
                                width="80"
                            />
                        </ListItem>
                    {/key}
                {/each}
            </List>
        {:else}
            <Block>
                You do not have any favourites yet!
            </Block>
        {/if}
    {/if}
</Page>
<style>
:global(.recipe-collection-page .item-title) {
    display: -webkit-box;        /* Use a flex container */
    -webkit-line-clamp: 3;       /* Number of lines to show */
    line-clamp: 3;               /* Standard property for compatibility */
    -webkit-box-orient: vertical; /* Vertical orientation for the box */
    overflow: hidden;            /* Hide overflow content */
    text-overflow: ellipsis;     /* Add ellipsis for truncated text */
}
</style>
<script>
    import { f7, Page, Navbar, Block, List, ListItem, SwipeoutActions, SwipeoutButton, Subnavbar, Searchbar} from 'framework7-svelte';
    import { getUserRecipes, selectedRecipe, userRecipes, getRecipeDetailsFromId } from '../stores/mealPlan';
    import { formatExternalRecipe, formatUserRecipe } from '../js/mealPlanHelpers';
    import { supabase, getRecipesForUser, removeRecipeForUser } from "../js/supabase";
    import { userDataStore, getUserFirstLastName } from "../stores/userInfo"
    import {userRecipeFavourites, addNewFavourite, removeFavourite, getUserFavourites} from '../stores/recipeFavourites'
    import { showToast } from "../js/generalHelpers";

    export let collectionType;
    console.log(`Collection type: ${collectionType}`)

    let favouriteRecipesData = [];
    let pageTitle = "Recipe Collection"; // Default

    const supportedCollectionTypes = [
        'USER_CREATED',
        'USER_FAVOURITES',
        'USER_HISTORY'
    ]

    async function onPageInit(){
        if(!collectionType){
            f7.dialog.alert('collectionType not provided.');
            return
        }
        if(!supportedCollectionTypes.includes(collectionType)){
            f7.dialog.alert(`Collection type "${collectionType}" is not supported.`);
            return
        }

        if(collectionType == 'USER_CREATED'){
            pageTitle = getUserFirstLastName($userDataStore).firstName ? getUserFirstLastName($userDataStore).firstName + "'s recipes" : "My recipes" 
            loadUserRecipes()
        } else if(collectionType == 'USER_FAVOURITES'){
            pageTitle = getUserFirstLastName($userDataStore).firstName ? getUserFirstLastName($userDataStore).firstName + "'s recipe favourites" : "My favourites"

            // Gather all favourites that are internal vs external
            let internalRecipes = [], externalRecipes = [];

            let recipeFavourites = await getUserFavourites();

            recipeFavourites.forEach(o => {
                if (o.recipe_id != null) internalRecipes.push(o);
                if (o.recipe_uri != null) externalRecipes.push(o);
            });

            if(internalRecipes.length > 0){
                // Internal recipes
                // 1 large query to the recipes table to gather internal recipes
                // Recipes showing up here will be user recipes that they have been shared 
                // or that they have favourited from a collaborated planner
                
                // TODO: Implement showing of Internal recipes in this list
                for(const internalRecipe of internalRecipes){
                    console.log('TODO: Process internal recipe')
                    
                    let recipeData;
                    try {
                        recipeData = await getRecipeDetailsFromId({
                            recipeId : internalRecipe.recipe_id,
                            isExternal : false
                        });
                    } catch(error) {
                        console.warn(`Could not get recipe details for ${internalRecipe.recipe_id}`)
                    }

                    // Push resulting data into favouriteRecipesData
                    let newRecipeFavourites = favouriteRecipesData;
                    newRecipeFavourites.push(recipeData);
                    favouriteRecipesData = newRecipeFavourites;
                }
            }

            if(externalRecipes.length > 0){
                // Edamam/external recipes
                // For loop to go through each external recipe and pull the data there as well
                // This is going to be the most often use-case at least initially for the app

                for(const externalRecipe of externalRecipes){
                    let recipeGatheringErrors = [];
                    try {
                        // Split the URI to get the ID
                        const recipeUriId = externalRecipe.recipe_uri.split('#')[1]
                        
                        // Await to get recipe details
                        const response = await supabase.functions.invoke(`recipe?recipe_id=${recipeUriId}`, {
                            method: "GET",
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        });

                        if (response.error) {
                            console.log('Error getting recipe');
                            console.log(error)
                            throw new Error(`Error getting recipe details. URI: ${externalRecipe.recipe_uri}`);
                        }

                        const formattedRecipe = formatExternalRecipe(response.data);

                        // Push resulting data into favouriteRecipesData
                        let newRecipeFavourites = favouriteRecipesData;
                        newRecipeFavourites.push(formattedRecipe);
                        favouriteRecipesData = newRecipeFavourites;
                    } catch(error){
                        recipeGatheringErrors.push(error.message)
                    }

                    if(recipeGatheringErrors.length > 0){
                        throw new Error('Multiple errors occurred: \n' + recipeGatheringErrors.join('\n'));
                    }
                }
            }

        }
    }

    async function loadUserRecipes(){
        const newRecipes = await getUserRecipes();
        userRecipes.set(newRecipes);
    }

    async function handleOnClickRecipe(recipe, itemTarget, formatRecipe = true) {
        if(recipe.type === "external"){
            await handleOnClickExternalRecipe(recipe)
        } else if(recipe.type === "internal"){
            await handleOnClickUserRecipe(recipe, itemTarget, formatRecipe)
        } else {
            throw new Error(`Recipe type of ${recipe.type} is not supported. 1928723`)
        }
    }

    async function handleOnClickUserRecipe(userRecipe, element, formatRecipe = true){
        // Prevent accidental clicks on web with swipeouts
        if(f7.$(element.event.currentTarget).parent().parent().hasClass('swipeout-opened')){
            return
        }

        // Open the recipe popup immediately and wait for the recipe to be loaded below
        f7.views.current.router.navigate(`/recipe/`, {
            props: {
                addToMealPlan: true,
                reloadRecipes: loadUserRecipes
            },
        });
        
        if(formatRecipe){
            userRecipe = await formatUserRecipe(userRecipe);
        }

        selectedRecipe.set(userRecipe);
    }

    function handleOnClickExternalRecipe(externalRecipe) {
        selectedRecipe.set(externalRecipe);

        f7.views.current.router.navigate(`/recipe/`, {
            props: {
                addToMealPlan: true,
                reloadRecipes: loadUserRecipes
            }
        });
    }

    async function handleRemoveRecipe(recipe){
        const removeResponse = await removeRecipeForUser(recipe.id);
        if (removeResponse.error) {
            throw new Error(`Error removing recipe`);
        } else {
            showToast(`Recipe removed`);
            await loadUserRecipes();
        }
    }
</script>
  