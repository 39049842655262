<Popup class="user-preference-questions" onPopupOpened={userPreferencesPopupOpened} onPopupClosed={userPreferenceClose}>
    <Page>
        <Navbar>
            <NavLeft>
                <Link popupClose>Cancel</Link>
            </NavLeft>
            <NavTitle>Meal Plan Wizard</NavTitle>
        </Navbar>
        <swiper-container 
        pagination-enabled={false} 
        pagination-type="progressbar" 
        allow-touch-move="false" 
        class="onboarding-popup-swiper" 
        space-between={50}>
            <swiper-slide>
                <div class="onboarding-swiper-slide">
                    <div style="font-size: 42px; margin-bottom: 20px; text-align: center;">
                        <!-- svelte-ignore a11y-missing-attribute -->
                        <img src="../images/undraw_environmental_study_re_q4q8.svg" style="width: 75%;"/>
                    </div>
                    <div class="onboarding-modal-title">
                        Welcome to the meal planning wizard!
                    </div>
                    <div class="onboarding-modal-text">
                        Boom boom boom
                    </div>
                </div>
                <Block style="position: absolute; width: 100%; bottom: 5%;">
                    <div style="text-align: -webkit-center;">
                        <Button 
                            style="max-width: 200px;" 
                            large round fill 
                            on:click={() => {
                                f7.swiper.get('.onboarding-popup-swiper').slideNext();
                                const swiperInstance = f7.swiper.get('.onboarding-popup-swiper');
                                swiperInstance.pagination.enable();
                            }}>
                            Okay!
                        </Button>
                    </div>
                </Block>
            </swiper-slide>
            <swiper-slide>
                <div style="overflow: scroll; height: 100%; box-sizing: border-box; padding-bottom: 100px;">
                    <Block>
                        Check and make sure that all of your preferences are up to date!
                    </Block>
                    <UserPreferencesUnifiedEditor/>
                </div>
                <Block style="
                    position: sticky;
                    width: 100%;
                    bottom: 0;
                    background: #fff;
                    margin: 0;
                    padding: 30px;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
                ">
                    <div style="text-align: -webkit-center;">
                        <Button style="max-width: 200px;" large round fill on:click={() => {
                            f7.swiper.get('.onboarding-popup-swiper').slideNext();
                        }}>Next</Button>
                    </div>
                </Block>
            </swiper-slide>
            {#each preferences as preference, preferenceIndex}
                <swiper-slide>
                    <div style="padding: 15px; font-size: 16px;">
                        <Link on:click={() => (f7.swiper.get('.onboarding-popup-swiper').slidePrev())}>
                            <i class="fa fa-chevron-left" style="margin-right: 5px;"/> 
                            Back
                        </Link>
                    </div>
                    <Block style="margin-top: 20px;">
                        <ListItem title={preference.text}>
                            {#if preference.type === "short_answer"}
                                <ListInput 
                                    bind:value={preferences[preferenceIndex].answer} 
                                    placeholder="Your answer here"
                                    on:change={() => {
                                        handlePreferencesChange({
                                            preferenceType : preference.type,
                                            preferenceId : preference.id,
                                            preferenceValue : preferences[preferenceIndex].answer
                                        })
                                    }}
                                    style="
                                        padding: 25px;
                                        border-style: solid;
                                        margin-top: 20px;
                                        border-radius: 8px;
                                        font-size: 16px;
                                    "
                                />
                            {:else}
                                <List inset strongIos outlineIos dividersIos style="margin: initial; margin-top: 20px;">
                                    {#if preference.selection === "single"}
                                        {#each preference.preferences_options as option, optionIndex}
                                            <ListItem
                                                radio
                                                value={option.id}
                                                title={option.text}
                                                checked={preferences[preferenceIndex].selectedOption === option.id}
                                                on:change={() => {
                                                    preferences[preferenceIndex].selectedOption = option.id;
                                                    handlePreferencesChange({
                                                        preferenceType : preference.type,
                                                        preferenceSelection : preference.selection,
                                                        preferenceId : preference.id,
                                                        preferenceValue : preferences[preferenceIndex]
                                                    });
                                                }}>
                                            </ListItem>
                                        {/each}
                                    {:else}
                                        {#each preference.preferences_options as option, optionIndex}
                                            <ListItem
                                                checkbox
                                                value={option.text}
                                                title={option.text}
                                                bind:checked={preferences[preferenceIndex].preferences_options[optionIndex].selected}
                                                on:change={async () => {
                                                    preferences[preferenceIndex].preferences_options = await handlePreferencesChange({
                                                        preferenceType : preference.type,
                                                        preferenceSelection : preference.selection,
                                                        preferenceId : preference.id,
                                                        preferenceValue : preferences[preferenceIndex].preferences_options,
                                                        selectedOption : preferences[preferenceIndex].preferences_options[optionIndex]
                                                    });
                                                }}
                                            ></ListItem>
                                        {/each}
                                    {/if}
                                </List>
                            {/if}
                        </ListItem>
                    </Block>
                    <Block style="position: absolute; width: 100%; bottom: 5%;">
                        <div style="text-align: -webkit-center;">
                            <Button style="max-width: 200px;" large round fill on:click={() => (f7.swiper.get('.onboarding-popup-swiper').slideNext())}>Next</Button>
                        </div>
                    </Block>
                </swiper-slide>
            {/each}
            <swiper-slide>
                <div class="onboarding-swiper-slide">
                    <div style="font-size: 42px; margin-bottom: 20px; text-align: center;">
                        <!-- svelte-ignore a11y-missing-attribute -->
                        <img src="../images/undraw_flowers_vx06.svg" style="width: 75%;"/>
                    </div>
                    <div class="onboarding-modal-title">
                        Great work!
                    </div>
                    <div class="onboarding-modal-text">
                        Zestyplan will use these preferences when generating your meal plans.
                    </div>
                </div>
                <Block style="position: absolute; width: 100%; bottom: 5%;">
                    <div style="text-align: -webkit-center;">
                        <Button style="max-width: 200px;" large round fill popupClose>Done</Button>
                    </div>
                </Block>
            </swiper-slide>
        </swiper-container>     
    </Page>
</Popup>

<script>
    import {
        f7,
        Popup,
        Page,
        Navbar,
        NavLeft,
        NavTitle,
        Link,
        Block,
        Button,
        ListItem,
        List,
        ListInput
    } from 'framework7-svelte';
    import { getPreferences, handlePreferencesChange } from '../stores/preferences';
    import { tick } from 'svelte';
    import UserPreferencesUnifiedEditor from "../components/UserPreferencesUnifiedEditor.svelte"

    let preferences = [];

    async function userPreferencesPopupOpened(){
        // Grab the current personalization questions to ask the user
        preferences = await getPreferences({
            mealPlannerQuestions : true
        });

        await tick(); // Wait for the DOM update

        const swiperInstance = f7.swiper.get('.onboarding-popup-swiper');
        
        swiperInstance.update(); // Update after manual slide insertion
    }

    async function userPreferenceClose(){
    }
</script>
<style>
  .onboarding-popup-swiper {
    height: 100%;
  }

  .onboarding-swiper-slide {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30%;
    padding: 20px;
  }

  .onboarding-modal-text {
    text-align: center;
    font-size: 18px;
  }
</style>